/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useDocument = (workspaceId: string, id: string) => {
  const url = `/v1/workspaces/${workspaceId}/documents/${id}`;

  const {
    data: document,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'document.show'>>(
    () => (id && workspaceId ? `${url}` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateDocument = async <RouteName = 'document.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const deleteDocument = async <RouteName = 'document.delete'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    document: document?.data,
    isLoading,
    isError: error,
    mutate,
    updateDocument,
    deleteDocument,
  };
};

export const useDocuments = (workspaceId: string) => {
  const url = `/v1/workspaces/${workspaceId}/documents`;

  const {
    data: document,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'document.index'>>(
    () => (workspaceId ? url : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const createDocument = async <RouteName = 'document.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });
  };

  return {
    documents: document?.data,
    isLoading,
    isError: error,
    mutate,
    createDocument,
  };
};
