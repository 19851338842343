/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useGuestRoom = (id?: string) => {
  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.guestShow'>>(
    () => (id ? `/v1/rooms/${id}?token=true` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  return {
    room: room?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useRoom = (workspaceId: string | null, id: string | null) => {
  const url = workspaceId && id ? `/v1/workspaces/${workspaceId}/rooms/${id}` : null;

  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.show'>>(
    () => (id ? `${url}?token=true` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateRoom = async <RouteName = 'room.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const deleteRoom = async <RouteName = 'room.delete'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    room: room?.data,
    isLoading,
    isError: error,
    mutate,
    updateRoom,
    deleteRoom,
  };
};

export const useRooms = (workspaceId: string) => {
  const url = `/v1/workspaces/${workspaceId}/rooms`;

  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const createRoom = async <RouteName = 'room.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });
  };

  return {
    rooms: room?.data,
    isLoading,
    isError: error,
    mutate,
    createRoom,
  };
};
