/// <reference path="../groupthink-js.d.ts" />

import { axios, fetcher, apiRequest } from '../lib';
import useSWR, { mutate } from 'swr';
import { AxiosRequestConfig } from 'axios';

export const useThread = (
  agendaId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
  }
) => {
  const { useRealtimeMutate } = options || {};

  let url = agendaId && id ? `/v1/agendas/${agendaId}/threads/${id}` : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${id}`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${id}`;
  }

  const {
    data: thread,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'thread.show'>>(url, fetcher, {
    keepPreviousData: true,
  });

  useRealtimeMutate?.('ThreadUpdated', !agendaId ? null : `agendas.${agendaId}`, url);
  useRealtimeMutate?.('MessageSent', !id ? null : `threads.${id}.messages`, url);
  useRealtimeMutate?.('MessageReactedTo', !id ? null : `threads.${id}.messages`, url);

  const markThreadAsRead = <RouteName = 'thread.mark_as_read'>(
    message_id: string,
    {
      setErrors,
      setIsMarkingRead,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsMarkingRead?: (isMarkingRead: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${id}/mark_as_read`, mutate, 'POST', {
      setErrors,
      setLoading: setIsMarkingRead,
      payload: { message_id },
      onSuccess,
    });

  return {
    thread: thread?.data,
    isLoading,
    isError: error,
    mutate,
    markThreadAsRead,
  };
};

export const useThreads = (
  agendaId?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.ThreadResource>;
  }
) => {
  const { useRealtimeCollection, useRealtimeMutate } = options || {};

  let url = agendaId ? `/v1/agendas/${agendaId}/threads` : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads`;
  }

  const {
    data: thread,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'thread.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const createThread = <RouteName = 'thread.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads`, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateThread = <RouteName = 'thread.update'>(
    threadId: string,
    { setErrors, setIsCreating, onSuccess, payload }: Groupthink.CreateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${threadId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const deleteThread = async <RouteName = 'thread.destroy'>(
    threadId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${threadId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  if (url && agendaId?.startsWith('RM')) {
    useRealtimeMutate?.('ThreadCreated', agendaId ? `rooms.${agendaId}` : null, url || null);
    useRealtimeMutate?.('ThreadUpdated', agendaId ? `rooms.${agendaId}` : null, url || null);
  } else if (url && agendaId?.startsWith('DO')) {
    useRealtimeMutate?.('ThreadCreated', agendaId ? `documents.${agendaId}` : null, url || null);
    useRealtimeMutate?.('ThreadUpdated', agendaId ? `documents.${agendaId}` : null, url || null);
  } else {
    useRealtimeMutate?.('ThreadCreated', agendaId ? `agendas.${agendaId}` : null, url || null);
    useRealtimeMutate?.('ThreadUpdated', agendaId ? `agendas.${agendaId}` : null, url || null);
  }

  useRealtimeCollection?.(
    'ThreadCreated',
    agendaId ? `agendas.${agendaId}` : null,
    mutate,
    url,
    null,
    true
  );
  useRealtimeCollection?.(
    'ThreadUpdated',
    agendaId ? `agendas.${agendaId}` : null,
    mutate,
    url,
    null,
    true
  );

  return {
    threads: thread?.data,
    isLoading,
    isError: error,
    mutate,
    createThread,
    updateThread,
    deleteThread,
  };
};

export const manipulateThreads = async <RouteName>(
  url: string,
  {
    method = 'POST',
    payload,
  }: {
    method?: AxiosRequestConfig['method'];
    payload?: Groupthink.RequestPayload<RouteName> | Record<string, unknown>;
  }
) => {
  await axios(url, {
    method,
    data: payload,
  });
};

export const markThreadAsRead = (agendaId: string, id: string, message_id: string) =>
  mutate(
    (key) => typeof key === 'string' && key.startsWith(`/v1/agendas`),
    manipulateThreads(`/v1/agendas/${agendaId}/threads/${id}/mark_as_read`, {
      method: 'POST',
      payload: { message_id },
    })
  );

/**
 * Takes an array of threads, ideally one that is returned by the useThreads hook, and reconstitutes it with the updated message,
 * or adds/deletes a reaction to/from the message.
 * To be used in the optimisticData and populateCache options of the useThreads mutate function.
 */
export const reconstituteThreads = ({
  user,
  threads,
  threadId,
  messageId,
  addReaction,
  deleteReaction,
  updatedMessage,
}: {
  threads: Groupthink.ThreadResource[] | undefined;
  user?: Groupthink.UserResource;
  threadId?: string;
  messageId?: string;
  addReaction?: Groupthink.MessageReactionResource;
  deleteReaction?: Groupthink.MessageReactionResource;
  updatedMessage?: Groupthink.MessageResource;
}) =>
  threads?.map((thread) => {
    if (thread.id === threadId && thread.oldest_message?.id === messageId) {
      return {
        ...thread,
        oldest_message: updatedMessage ?? {
          // if updatedMessage is provided, use it, otherwise take the existing oldest_message and merge it with the added/removed reaction payload
          ...thread.oldest_message,
          reactions: [
            ...// If we're deleting a reaction, we need to filter it out of the reactions array
            (
              (deleteReaction &&
                thread.oldest_message?.reactions?.filter(
                  (r) => r.content !== deleteReaction.content
                )) ||
              thread.oldest_message?.reactions ||
              []
            )
              // If we're adding a reaction, we need to add it to the reactions array IFF it doesn't already exist
              .concat(
                addReaction &&
                  user &&
                  !thread.oldest_message?.reactions?.some(
                    (r) => r.content === addReaction.content && r.sent_by.id === user.id
                  )
                  ? {
                      ...addReaction,
                      sent_by: user,
                      message_id: messageId,
                    }
                  : []
              ),
          ],
        },
      };
    }

    return thread;
  }) || [];

export const threadsWithNewMessage = ({ threadId, threads, newMessage, user }) => {
  let newThreads = [...threads];
  if (threadId === 'new') {
    newThreads = [
      ...threads,
      {
        // @ts-ignore
        id: threadId,
        name: 'New Thread',
        message_count: 1,
        has_unread: false,
        oldest_message: newMessage,
        created_by: user,
        created_at: new Date().toISOString(),
        updated_by: user,
        updated_at: new Date().toISOString(),
      },
    ];
  } else {
    newThreads = [
      ...threads.map((thread) => {
        if (thread.id === threadId) {
          const updatedThread = {
            ...thread,
            message_count: (parseInt(thread.message_count) + 1).toString(),
            updated_at: new Date().toISOString(),
            updated_by: user,
          };
          console.log(`updatedThread is`, updatedThread);
          return updatedThread;
        }
        return thread;
      }),
    ];
  }

  return newThreads;
};
