/// <reference path="../groupthink-js.d.ts" />

import useSWR from 'swr';
import { fetcher, apiRequest } from '../lib';
import { useCallback } from 'react';

interface Template {
  id: string;
  name: string;
}

interface UseTemplatesOptions {
  setErrors?: (errors: any) => void;
  setIsCreating?: (isCreating: boolean) => void;
  onSuccess?: () => void;
  payload?: any;
}

interface UseTemplatesResponse {
  templates: Template[] | undefined;
  isLoading: boolean;
  isError: any;
  mutate: () => void;
  createTemplate: (
    agendaId: string,
    templateId: string,
    options?: UseTemplatesOptions
  ) => Promise<void>;
}

export const useTemplates = (agendaId: string, agendaItemId: string): UseTemplatesResponse => {
  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'agendaTemplate.index'>
  >(`/v1/agendas/${agendaId}/templates`, fetcher, {
    keepPreviousData: true,
  });

  return {
    // @ts-ignore
    templates: data || [], // Directly return the array from API
    isLoading,
    isError: error,
    mutate,
    createTemplate: useCallback(
      async <RouteName = 'agendaTemplate.applyTemplate'>(
        agendaId: string,
        templateId: string,
        options: UseTemplatesOptions = {}
      ) => {
        const {
          setErrors = () => {},
          setIsCreating = () => {},
          onSuccess = () => {},
          payload,
        }: Groupthink.CreateOperationOptions<RouteName> = options;
        setIsCreating(true);
        try {
          await apiRequest<RouteName>(
            `/v1/agendas/${agendaId}/templates/${templateId}/applyTemplate`,
            mutate,
            'POST',
            {
              setErrors,
              setLoading: setIsCreating,
              payload,
              onSuccess,
            }
          );
        } catch (err) {
          setErrors(err);
        } finally {
          setIsCreating(false);
        }
      },
      [mutate]
    ),
  };
};
